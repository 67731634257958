import React, { useEffect, useMemo } from "react";

import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { Field } from "react-final-form";
import { TextField, Radio } from "final-form-material-ui";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Fab from "@material-ui/core/Fab";
import MTextField from "@material-ui/core/TextField";

import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import GraphicEqIcon from "@material-ui/icons/GraphicEq";

import { GetCurrentExchangeRate } from "../lib/api/exchangerates";

import numeral from "numeral";

import { commoditiesAllSortedMappedforCombo } from "../lib/api/commodity";
import { VarietiesByCommodityMappedForCombo } from "../lib/api/variety";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Exchange = {
  usd: "exchange.usd",
  gbp: "exchange.gbp",
  cad: "exchange.cad",
  eur: "exchange.eur",
};

export const OrderHeader = withStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "grid",
      gridTemplateColumns: "max-content auto",
      gap: "10px",
    },
    formContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      backgroundColor: "#cfd8dc",
      borderRadius: "5px",
      padding: "10px",
      paddingLeft: "15px",
      paddingRight: "15px",
      columnGap: "15px",
    },
    currencyContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "10px",
    },
    currencyFieldsContainer: {
      // width: "340px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      gap: "10px",
    },
    currencyFab: {
      backgroundColor: "green",
      color: "white",
    },
    commentsContainer: {
      backgroundColor: "white",
      maxWidth: "1000px",
      padding: "10px",
      display: "flex",
      flexDirection: "column",
    },
    commentsCountsContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "30px",
    },
    headerInput: {
      marginTop: "-15px",
      width: "340px",
    },
    gridRow: {
      display: "grid",
      gridTemplateColumns: "100px 1fr",
      alignItems: "center",
      justifyContent: "flex-start",
      columnGap: "10px",
      height: "45px",
      maxWidth: "500px",
    },
  }),
)(({ handleChangeFarm, groupMaster, orderid, producersMapped, variety, weeksMapped, exchange, handleSetExchange, classes, form }: any) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({
    date: undefined,
    base: undefined,
    rates: {
      usd: undefined,
      cad: undefined,
      eur: undefined,
      gbp: undefined,
    },
  });

  const [varieties, setVarieties] = React.useState([]);
  const [commodities, setCommodities] = React.useState([]);

  const maxRows = 8,
    maxRowCharLength = 150 * 8;

  const [commentRows, setCommentRows] = React.useState(0);
  const [commentChars, setCommentChars] = React.useState(0);

  const [weeksMappedClean, setWeeksMappedClean] = React.useState([]);

  useEffect(() => {
    if (form.getState().values.week) {
      const weeksSorted = weeksMapped.sort((a, b) => a.display.localeCompare(b.display));
      const currentWeekIndex = weeksMapped.findIndex((item) => item.value === form.getState().values.week);
      const filteredWeeks = currentWeekIndex !== -1 ? weeksMapped.slice(currentWeekIndex) : [];
      setWeeksMappedClean(filteredWeeks);
    } else {
      setWeeksMappedClean(weeksMapped);
    }
  }, [weeksMapped, form]);

  useEffect(() => {
    loadData();
  }, [exchange]);

  const loadData = () => {
    commoditiesAllSortedMappedforCombo().then((result) => {
      setCommodities([...result]);
      if (variety) {
        VarietiesByCommodityMappedForCombo(variety.commodity_id).then((resultVars) => {
          setVarieties([...resultVars]);
        });
      }
    });
  };

  const handleClickOpen = () => {
    GetCurrentExchangeRate().then((data) => {
      const messge = {
        date: data.date,
        base: data.base,
        rates: {
          usd: numeral(data.rates.USD).format("0.000").toString(),
          cad: numeral(data.rates.CAD).format("0.000").toString(),
          eur: numeral(data.rates.EUR).format("0.000").toString(),
          gbp: numeral(data.rates.GBP).format("0.000").toString(),
        },
      };
      setMessage(messge);
      setOpen(true);
    });
  };

  const SetExchangerates = () => {
    Object.keys(exchange).map((currency) => {
      exchange[currency] = numeral(message.rates[currency]).format("0.00").toString();
    });
    handleSetExchange(exchange);
    handleClose();
    document.getElementsByName(Exchange.usd)[0].focus();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCommodity = (value) => {
    VarietiesByCommodityMappedForCombo(value).then((resultVars) => {
      setVarieties([...resultVars]);
    });
  };

  useEffect(() => {
    /*
      150 = amount of text in one line on pdf
      8 = max amount of lines in container on pdf
    */
    const comments = form.getState().values.comment || "",
      maxCharPerRow = 150;

    const rows = comments.split("\n").reduce((count: number, comment: string) => {
      if (comment.length < maxCharPerRow) {
        count++;
      } else {
        count = count + Math.ceil(comment.length / maxCharPerRow);
      }
      return count;
    }, 0);

    setCommentRows(rows);
    setCommentChars(comments.length);
  }, [form.getState().values.comment]);

  return (
    <div id="OrdersCreateNewUnstyledMain" className={classes.container}>
      <div className={classes.formContainer}>
        <DropdownComponent classes={classes} form={form} handleChange={handleChangeFarm} data={producersMapped} label="Farm" name="farm" disabled={false} />
        <TextFieldComponent classes={classes} label="Commission%" name="commission" disabled={false} />

        <RadioGroupComponent classes={classes} label="Purchase Terms">
          <FormControlLabel label="ExWorks" control={<Field name="exworks" component={Radio} type="radio" value="true" />} />
          <FormControlLabel label="DIP" control={<Field name="exworks" component={Radio} type="radio" value="false" />} />
        </RadioGroupComponent>
        <DropdownComponent classes={classes} form={form} handleChange={handleChangeCommodity} data={commodities} label="Commodity" name="commodity" disabled={false} />

        <RadioGroupComponent classes={classes} label="Producer Deal Type">
          <FormControlLabel label="FIXED" control={<Field name="fixed" component={Radio} type="radio" value="true" />} />
          <FormControlLabel label="MGP" control={<Field name="fixed" component={Radio} type="radio" value="false" />} />
        </RadioGroupComponent>
        <DropdownComponent classes={classes} form={form} handleChange={undefined} data={varieties} label="Variety" name="variety" disabled={false} />

        <RadioGroupComponent classes={classes} label="Producer Payment">
          <FormControlLabel label="Local Currency" control={<Field name="local" component={Radio} type="radio" value="true" />} />
          <FormControlLabel label="Foreign Currency" control={<Field name="local" component={Radio} type="radio" value="false" />} />
        </RadioGroupComponent>
        <DropdownComponent classes={classes} form={form} handleChange={undefined} data={weeksMappedClean} label="Week" name="week" disabled={!groupMaster} />

        {variety ? <TextFieldComponent classes={classes} label="Order Number" name="ordernum" disabled={true} /> : <div></div>}
        <div className={classes.gridRow}>
          <div style={{ textAlign: "right" }}>
            <label>Current ROE:</label>
          </div>
          <div className={classes.currencyContainer}>
            <div className={classes.currencyFieldsContainer}>
              <Field name={Exchange.usd} component={TextField} type="text" label="USD" margin="dense" disabled={!groupMaster} />
              <Field name={Exchange.cad} component={TextField} type="text" label="CAD" margin="dense" disabled={!groupMaster} />
              <Field name={Exchange.eur} component={TextField} type="text" label="EUR" margin="dense" disabled={!groupMaster} />
              <Field name={Exchange.gbp} component={TextField} type="text" label="GBP" margin="dense" disabled={!groupMaster} />
            </div>
            <div>
              <Fab className={classes.currencyFab} size={"medium"} aria-label="Edit" onClick={handleClickOpen}>
                <GraphicEqIcon />
              </Fab>
            </div>
          </div>
        </div>
      </div>

      {groupMaster && orderid && (
        <div className={classes.commentsContainer}>
          <div>
            <Field component={TextField} id="outlined-multiline-flexible" name="comment" multiline={true} fullWidth={true} rowsMax="6" rows="6" label="Producer Comments" />
            <div className={classes.commentsCountsContainer}>
              <p style={{ color: commentRows > maxRows ? "red" : "black" }}>
                Rows ({commentRows}/{maxRows})
              </p>
              <p style={{ color: commentChars > maxRowCharLength ? "red" : "black" }}>
                Characters ({commentChars}/{maxRowCharLength})
              </p>
            </div>
          </div>
          <Field component={TextField} id="outlined-multiline-flexible" name="impala_comments" multiline={true} fullWidth={true} rowsMax="6" rows="6" label="Shipper Comments" />
        </div>
      )}

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Current Exchange Rates"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message && (
              <>
                <b>Date: </b>
                {message.date}
                <br />
                <b>Base: </b>
                {message.base}
                <br />
                <br />
                <b>USD: </b>
                {message.rates.usd}
                <br />
                <b>CAD: </b>
                {message.rates.cad}
                <br />
                <b>EUR: </b>
                {message.rates.eur}
                <br />
                <b>GBP: </b>
                {message.rates.gbp}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={SetExchangerates} color="primary">
            OK
          </Button>
          <Button onClick={handleClose} color="secondary" autoFocus={true}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

const DropdownComponent = ({ classes, form, name, label, data, handleChange, disabled }) => {
  const selectedValue = useMemo(() => {
    const value = form.getState().values[name];
    const selectedValue = (data || []).find((item) => item.value == value);
    if (selectedValue) {
      return selectedValue;
    }
    return { display: "", value: 0 };
  }, [form.getState().values[name], data]);

  return (
    <div className={classes.gridRow}>
      <div style={{ textAlign: "right" }}>
        <label>{label}:</label>
      </div>
      <div>
        <Field
          name={name}
          formControlProps={{ className: classes.headerInput }}
          render={({ input, meta }) => {
            return (
              <Autocomplete
                {...input}
                {...meta}
                fullWidth
                options={data}
                disabled={disabled}
                value={selectedValue}
                getOptionLabel={(option: any) => option.display}
                onChange={(_, value) => {
                  form.change(name, value.value);
                  input.onChange(value.value);
                }}
                renderInput={(params) => <MTextField {...params} variant="standard" />}
              />
            );
          }}
          parse={(value) => {
            if (handleChange) {
              handleChange(value);
            }
            return value;
          }}
        />
      </div>
    </div>
  );
};

const TextFieldComponent = ({ classes, label, name, disabled }) => {
  return (
    <div className={classes.gridRow}>
      <div style={{ textAlign: "right" }}>
        <label>{label}: </label>
      </div>
      <div>
        <Field fullWidth required name={name} component={TextField} type="text" disabled={disabled} />
      </div>
    </div>
  );
};

const RadioGroupComponent = ({ children, classes, label }) => {
  return (
    <div className={classes.gridRow}>
      <div style={{ textAlign: "right" }}>
        <label>{label}: </label>
      </div>
      <div>
        <RadioGroup row>{children}</RadioGroup>
      </div>
    </div>
  );
};
